.hero {
  padding-top: 3rem;

  &__container {
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__logo {
    flex: 0 0 auto;
    min-width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    box-shadow: 20px 24px 24px 0px rgba(0, 0, 0, 0.35);
    border-radius: var(--bs-border-radius-lg);
    border: 8px solid var(--bt-orange, #F25C05);
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 992px) {
      min-width: 300px;
      width: 300px;
      height: 300px;
      margin: 0 auto;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin-bottom: 2rem;

    h1 {
      line-height: 1.2;

      &:after {display: none}

      @media screen and (max-width: 992px) {
        font-size: 3rem;
        text-align: center;
      }
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;

    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }
}
