.footer {
  background: var(--bs-black);
  padding: 2rem 0;

  &__container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &__left {
    display: flex;
    gap: 1.5rem;
  }

  &__right {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 992px) {
      justify-content: flex-start;
      padding-left: 144px;
    }
  }

  &__logo {

  }

  &__logo-link {

    img {
      width: 120px;
      height: auto;
      border: 4px solid var(--bt-orange);
      box-shadow: 20px 24px 24px 0px rgba(0, 0, 0, 0.35);
      border-radius: 50%;
    }
  }

  &__bloomtech {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    margin-bottom: 1rem;
  }

  &__paragraph {
    margin-bottom: 0;
    font-size: 15px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    margin-bottom: .5rem;
    text-align: right;
    @media screen and (max-width: 992px) {
      text-align: left;
    }
  }

  &__list-link {
    text-align: right;
    color: var(--bt-yellow);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;

    @media screen and (max-width: 992px) {
      text-align: left;
    }
  }
}
