.accordion {
  --bs-accordion-color: var(--bs-white);
  --bs-accordion-bg: var(--bt-glass-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bt-glass-border);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 2rem;
  --bs-accordion-inner-border-radius: 2rem;
  --bs-accordion-btn-padding-x: 1.5rem;
  --bs-accordion-btn-padding-y: 1.5rem;
  --bs-accordion-btn-color: var(--bs-white);
  --bs-accordion-btn-bg: var(--bt-glass-bg);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-white);
  --bs-accordion-active-bg: var(--bg-blue);
  --bs-card-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &-button {
    font-size: 1.5rem;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);

    h1, h2, h3, h4, h5, h6 {
      font-size: 1.5rem;

      &:after {
        display: none;
      }
    }
  }

  &-header {
    &:after {
      display: none;
    }
  }
}
