.team {
  &__container {

  }

  &__grid {
    display: grid;
    grid-gap: 2.25rem;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr))
  }

  &__item {
    display: flex;
    justify-content: center;
  }
}
