:root {
  --bg-blue: #141323;
  --bt-orange: #F25C05;
  --bt-yellow: #F29727;
  --bt-burgundy: #400101;
  --bt-primary: #022859;
  --bs-btn-border-radius: 100px;
  --bs-border-radius-lg: 100px;
  --bs-border-width: 1px;
  --bs-body-color: var(--bs-white);
  --bs-body-bg: var(--bg-blue);
  --bs-body-color-rgb: rgb(194, 194, 194);

  --bt-glass-border: #333657;
  --bt-glass-bg: rgba(2, 40, 89, 0.50);
  --bt-glass-blur: blur(20px);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: var(--bg-blue);
  font-family: 'Inter', sans-serif;
  color: var(--bs-white);
}

section, .section {
  padding: 2rem 0;
}
