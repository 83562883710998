ul, ol, li, p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  color: var(--bs-white);
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.5rem;
  color: var(--bs-white);
  font-family: 'Oswald', Arial, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: 0px;
    left: 0;
    background: var(--bs-white);
  }

  &.header-alt {
    color: var(--bs-white);
    font-family: 'Inter', sans-serif;
  }
}

h1 {
  font-size: 4rem;
  line-height: 1.6;
}

h2 {
  font-size: 3.5rem;
  line-height: 1.6;
}

h3 {
  font-size: 2rem;
  line-height: 1.6;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.5;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.5;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
}

p {
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: 1.75;
}

.subtitle {
  font-family: 'BirchCTT', Arial, cursive;
  font-size: 2.125rem;
  color: var(--bs-warning);
}

.lead {
  font-weight: 500;
}

.info {
  display: flex;
  margin-bottom: .5rem;
  gap: .5rem;

  &-label, &-content {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  a {
    color: var(--bs-white);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      color: var(--bs-warning);
    }
  }

  &.info-social {
    align-items: center;
  }
}

.price {
  font-size: 1.5rem;
  font-weight: 700;

  &-small {
    font-size: 1.5rem;
  }
}

.paragraph {
  color: var(--bs-black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link {

  &-green {
    color: var(--bs-success);
  }

  &-yellow {
    color: var(--bs-warning);
  }
}

.text {
  &__orange {
    color: var(--bt-orange) !important;
  }
}

.tag {
  display: inline-flex;
  padding: .25rem .75rem;
  align-items: center;
  gap: 4px;
  border-radius: var(--bs-border-radius-lg);
  border: 1px solid var(--bs-white);
  text-decoration: none;
  cursor: pointer;
  font-size: 1.175rem;
  line-height: 1;
  white-space: nowrap;
  transition: ease-in-out .25s;

  &.tag__dot {
    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      background: var(--bt-yellow);
      border-radius: 50%;
    }
  }

  &:hover {
    background: var(--bs-white);
    color: var(--bs-black);
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  gap: 1rem .5rem;
  list-style: none;

  &__item {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  &.tags-divided {
    .tags-list__item {

      &:after {
        content: '';
        width: .5rem;
        height: .5rem;
        background: var(--bt-glass-border);
        border-radius: 50%;
        display: block;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}
