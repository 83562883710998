.btn {
  border-radius: var(--bs-btn-border-radius);
}

.btn-group-lg>.btn, .btn-lg {
  border-radius: var(--bs-btn-border-radius);
  font-size: 1rem;
  padding: 1rem;
}

.btn-outline-warning {
  --bs-btn-color: var(--bt-yellow);
  --bs-btn-active-border-color: var(--bt-yellow);
  --bs-btn-hover-bg: var(--bt-yellow);
  --bs-btn-hover-border-color: var(--bt-yellow);
}
