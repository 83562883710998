.avatar {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  max-width: 12rem;
  padding: .75rem;


  &__image {
    margin-bottom: 1rem;
    text-align: center;

    img {
      margin: 0 auto;
      //border: 2px solid var(--bt-orange);
      border-radius: 50%;
    }
  }

  &__sign {
    font-family: 'Oswald', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: .5rem;
    text-align: center;
  }

  &__subsign {
    font-size: 0.938rem;
    font-style: normal;
    line-height: 1.4;
    text-align: center;
    color: var(--bt-orange);
  }
}
