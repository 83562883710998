.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: var(--bs-white);
  --bs-card-subtitle-color: var(--bs-white);;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 2rem;
  --bs-card-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --bs-card-inner-border-radius: 2rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-height: 100%;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bt-glass-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bt-glass-border);
  border-radius: var(--bs-card-border-radius);
  backdrop-filter: var(--bt-glass-blur);

  &-body__cols.card-body {
    padding: 2rem 2rem 1.5rem 2rem;
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__header {
    padding: 2rem 2rem 1.5rem 2rem;
  }

  &__content {
    padding: 2rem 2rem 1.5rem 2rem;
    display: flex;
    gap: 2rem;
  }

  &__content-info {
    flex: 1 1 auto;
  }

  &__content-image {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 0 0 auto;
    min-width: 128px;
    text-align: center;
  }

  h1, h2, h3, h4, h5, h6 {
    &:after {
      background: var(--bt-orange);
    }
  }
}
