.header {
  --header-height: 5.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
  //background: var(--bg-blue);
  backdrop-filter: var(--bt-glass-blur);

  &__container {
    display: flex;
    flex-wrap: nowrap;
    height: var(--header-height);
  }

  &__left {
    flex: 0 0 auto;
    align-items: center;
    display: flex;
    gap: 1.25rem;
  }

  &__logo-desktop {

  }

  &__logo-text {
    color: var(--bs-white);
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 auto;
    gap: 1.25rem;
    align-items: center;
  }

  &__nav {
    @media screen and (max-width: 992px) {
      display: none;

      &.active {
        display: flex;
        justify-content: center;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        overflow: auto;
        padding: 2rem;
        background: var(--bt-glass-bg);
        backdrop-filter: var(--bt-glass-blur);
        border: 1px solid var(--bt-glass-border, #333657);
        border-radius: 1rem 1rem 0 0;
        z-index: 1;

        .header__nav-list {
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        }

        .header__nav-item {}
        .header__nav-link {
          font-size: 1.5rem;
        }
      }
    }

    &-overlay {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: rgba(0,0,0,.75);

      @media screen and (max-width: 992px) {
        &.active {
          display: block;
        }
      }
    }
  }

  &__nav-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-link {
    color: var(--bs-white);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.625rem;
    position: relative;

    &:hover {
      color: var(--bt-yellow);
    }

    &:after {
      position: absolute;
      bottom: .25rem;
      width: calc(100% - 1.25rem);
      height: .125rem;
      left: 0;
      right: 0;
      margin: auto;
      background: var(--bt-yellow);
    }

    &.active {
      color: var(--bt-yellow);
    }

    &.active:after {
      content: '';
    }
  }
}

.hamburger {
  background: transparent;
  color: var(--bs-white);
  border: none;
  box-shadow: none;
  display: none;
  width: 2.5rem;
  height: 2.5rem;

  @media screen and (max-width: 992px) {
    display: block;
  }
}
