.contacts {

  &__container {

  }

  &__card {
    padding: 7rem;
    border: 1px solid var(--bt-glass-border);
    border-radius: 2rem;
    background: url(../img/contact.webp), lightgray 50% / cover no-repeat;

    @media screen and (max-width: 992px) {
      padding: 4rem;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: flex;
    align-content: center;
    align-items: center;
    gap: .5rem;
    margin-bottom: .5rem;
    font-size: .938rem;

    a {
      color: var(--bs-white);
      text-decoration: none;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 992px) {
      .btn {
        width: 100%;
      }
    }
  }
}
