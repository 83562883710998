.form-control {
  background-color: transparent;
  border-color: var(--bt-glass-border);

  &:focus {
    background-color: transparent;
  }
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  color: var(--bt-orange);
}
